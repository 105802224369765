import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["result", "resultWrapper", "submitBtn", "sideA", "sideB"];
  static values = { valid: Boolean };

  initialize() {
    this.updateValid();
    this.updateResultOptions();
  }

  playerChanged(event) {
    this.updateValid();
    this.updateResultOptions();
  }

  resultChanged(event) {
    this.updateValid();
  }

  private;

  validValueChanged() {
    console.log("valid value changed:", this.validValue);
    this.toggleDisabled();
  }

  toggleDisabled() {
    this.submitBtnTarget.disabled = !this.validValue;
  }

  updateValid() {
    // both sides selected and a result
    if (
      this.sideATarget.value &&
      this.sideBTarget.value &&
      this.resultTarget.value
    ) {
      this.validValue = true;
      return;
    }

    // a forfeit and b selected (no score)
    if (this.sideATarget.value === "-1" && this.sideBTarget.value > 0) {
      this.validValue = true;
      return;
    }

    // b forfeit and a selected (no score)
    if (this.sideBTarget.value === "-1" && this.sideATarget.value > 0) {
      this.validValue = true;
      return;
    }

    this.validValue = false;
  }

  updateResultOptions() {
    // one or more missing selections. dont show result select
    if (!this.sideATarget.value || !this.sideBTarget.value) {
      this.hideResult();
      return;
    }

    // a forfeit selected. dont show result select
    if (this.sideATarget.value === "-1" || this.sideBTarget.value === "-1") {
      this.hideResult();
      return;
    }

    // @TODO handle same side selected

    // both sides selected. show result select
    this.handlePlayerSelection();
    this.showResult();
  }

  showResult() {
    this.resultWrapperTarget.classList.remove("d-none");
  }

  hideResult() {
    this.resultWrapperTarget.classList.add("d-none");
  }

  // @TODO Break this up
  handlePlayerSelection(event, side) {
    const sideAName = this.getSelectedNameTextFromTarget(this.sideATarget);
    const sideBName = this.getSelectedNameTextFromTarget(this.sideBTarget);

    this.resultTarget.options.length = 1; // leave the first option and clear the rest

    let optionIndex = 1;
    for (let i = 3; i < 6; i++) {
      this.resultTarget.options[optionIndex] = new Option(
        `${sideAName} in ${i} games`,
        `A_${i}`
      );
      optionIndex++;
    }
    for (let i = 3; i < 6; i++) {
      this.resultTarget.options[optionIndex] = new Option(
        `${sideBName} in ${i} games`,
        `B_${i}`
      );
      optionIndex++;
    }
  }

  getSelectedNameTextFromTarget(target) {
    const selectedOption = target.options[target.selectedIndex];
    const selectedText = selectedOption.text;
    return selectedText;
  }
}
